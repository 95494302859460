import React from "react";

import {
	TextInput,
	ReferenceInput,
	ReferenceArrayInput,
	SelectInput,
	required
} from 'react-admin';
import {useFormState, useForm} from 'react-final-form';

import {GridForm, FormGroupGrid, GridInput} from '../../../components/GridForm/';
import CheckboxGroupInputEx from "../../../components/_extensions/react-admin/input/CheckboxGroupInputEx";
import { CompanySelect } from '../../../components/inputs/CompanySelect';


export const validatePrimaryAndSecondaryItems = (anotherItemsSource, anotherItemsName) => (value, allValues) => {
	const anotherItemsValues = allValues[anotherItemsSource];
	const intersection = value.filter(x => anotherItemsValues.includes(x));
	if (intersection.length !== 0) {
		return { 
			message: "osfc.messages.validation.theSamePrimaryAndSecondaryItems", 
			args: { anotherItemsName, intersection } 
		};
	};
};


export const PortAreaBaseForm = props => {
	const { values } = useFormState();
	const form = useForm();

	const optionRenderer = choice => `${choice.name}`;
	const byTerminalFilter = { terminal_id: [values["terminal_id"]] };
	const disabledAreasForPorts = props.reference === "areas"; 

	const handleTerminalChange = () => {
		form.change(props.primary_items, []);
		form.change(props.secondary_items, []);
	};

	const isAreas = props.reference === "ports";

	return (
		<GridForm {...props}>
			<FormGroupGrid>
				<GridInput xs={12} sm={isAreas ? 6 : 4} md={isAreas ? 3 : 4} component={TextInput} source="name" validate={[required()]} autoFocus/>
				<GridInput xs={12} sm={isAreas ? 6 : 4} md={isAreas ? 3 : 4} component={TextInput} source="comment" />
				<GridInput xs={12} sm={isAreas ? 6 : 4} md={isAreas ? 3 : 4} validate={[required()]}>
					<ReferenceInput source="terminal_id" reference="terminal" onChange={handleTerminalChange}>
						<SelectInput sourceText="name" />
					</ReferenceInput>
				</GridInput>
				{isAreas ? 
					<GridInput xs={12} sm={6} md={3} component={CompanySelect} source="customer_id" reference="customer-companies" />
				: null}
			</FormGroupGrid>

			<FormGroupGrid>
				<ReferenceArrayInput source={props.primary_items} defaultValue={props.defaultValue} reference={props.reference}
									 label={"resources." + props.resource + ".fields." + props.primary_items} filter={byTerminalFilter}> 
					<GridInput xs={12} sm={6} component={CheckboxGroupInputEx} optionValue="id" optionText={optionRenderer} 
							   fullWidth options={{size: "small"}} maxChoices={12} disabled={disabledAreasForPorts}
							   validate={validatePrimaryAndSecondaryItems(props.secondary_items, props.secondaryItemsLabel)} />
				</ReferenceArrayInput>

				<ReferenceArrayInput source={props.secondary_items} defaultValue={props.defaultValue} reference={props.reference}
									 label={"resources." + props.resource + ".fields." + props.secondary_items} filter={byTerminalFilter}>
					<GridInput xs={12} sm={6} component={CheckboxGroupInputEx} optionValue="id" optionText={optionRenderer} 
							   fullWidth options={{size: "small"}} maxChoices={12} disabled={disabledAreasForPorts}
							   validate={validatePrimaryAndSecondaryItems(props.primary_items, props.primaryItemsLabel)} />
				</ReferenceArrayInput>
			</FormGroupGrid>
		</GridForm>
	);
}

export const PortBaseForm = props => (<PortAreaBaseForm {...props} reference={"areas"}
														primary_items={"primary_areas"}
														secondary_items={"secondary_areas"}
														 />)
export const AreaBaseForm = props => (<PortAreaBaseForm {...props} reference={"ports"}
														primary_items={"primary_ports"}
														secondary_items={"secondary_ports"}
														 />)
