import React from "react";
import {Create, SimpleForm} from 'react-admin';

import {SaveWithCancelToolbar} from '../../../components/toolbars/SaveWithCancelToolbar';

import {PortBaseForm, AreaBaseForm} from '../Forms/PortAreaBaseForm';


export const PortCreate = (props) => (
	<Create {...props}>
		<SimpleForm submitOnEnter={false} toolbar={<SaveWithCancelToolbar/>} redirect="list">
			<PortBaseForm/>
		</SimpleForm>
	</Create>
);

export const AreaCreate = (props) => (
	<Create {...props}>
		<SimpleForm submitOnEnter={false} toolbar={<SaveWithCancelToolbar/>} redirect="list">
			<AreaBaseForm defaultValue={[]}/>
		</SimpleForm>
	</Create>
);
