import React, { useCallback, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import { differenceInMinutes } from 'date-fns';

import {
	TextField, EditButton, Pagination, Button,
	List, SelectInput, Loading, ShowButton,
	FunctionField, SelectField, 
	useTranslate, useRefresh, useGetList, useListContext, 
	useRecordContext, useNotify, usePermissions,
	useMutation,
	NumberInput,
	translate,
} from 'react-admin';
import {
	format as dateFnsFormat,
} from 'date-fns';

import { makeStyles } from '@material-ui/core/styles';

import Tooltip from '@material-ui/core/Tooltip';
import DescriptionIcon from '@material-ui/icons/Description';
import SubjectIcon from '@material-ui/icons/Subject';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ArrivedIcon from '@material-ui/icons/LocalShipping';

import { CompPerm } from '../../../entities/Permission';
import { ScrollingWrapper } from '../../../components/ScrollingWrapper';
import { EqualField, BooleanField } from '../../../components/fields/EqualField';
import { DateFormatField } from '../../../components/fields/DateFormatField';
import { LongTextField } from '../../../components/fields/LongTextField';
import { PriorityField } from '../../../components/fields/PriorityField';
import { ObjectChipField } from '../../../components/fields/ObjectChipField';
import { EditButtonField, ShowButtonField } from '../../../components/fields/LinkField';
import { YesNoField } from "../../../components/fields/YesNoField";
import { SelectDisabledInput } from "../../../components/inputs/SelectUppercaseInput";
import { CssRowFieldCellWrapper } from "../../../components/fields/CssFieldCellWrapper";
import { TextEditableField } from '../../../components/fields/EditableField';
import { ReferenceEditableField, ButtonEditableCustomField } from '../../../components/fields/EditableField';
import { Datagrid } from '../../../components/CustomizableDataGrid/';
import { toPrice } from '../../../components/fields/PriceField';
import { NoneOrValueField } from '../../../components/fields/NoOrValueField';
import { TimeInput, YesNoInput, DateInput } from '../../../components/inputs';
import { PortInput } from '../../../components/inputs/PortInput';
import { GridInput } from "../../../components/GridForm";
import { SearchResultDocumentsRowExpand } from "../../../components/SearchResultRowExpand";
import { SelectEnumField } from '../../../components/fields/SelectEnumField';
import { OutgoingDocItemsPopup } from '../../../components/dialogs/OutgoingDocItemsPopup';

import ChatMessageCountField from "../../../components/fields/ChatMessageCountField";
import WrapperWithDragAndDrop from '../../../components/DraggableDatagrid';
import CustomerField from '../../../components/fields/CustomerField';
import TransDriverField from '../../../components/fields/TransDriverField';

import {
	CoolerType, OrderService, OrderServices, OrderStatus, 
	OrderStatusCustomerEdit, OrderStatusNotactive, OrderStatusOnCompleteEdit, 
	UserRole, UserRoleByCompanyType, useCommodityChoices
} from '../../../entities/';
import { LinkOrderType } from '../../../entities/LinkOrderType';
import { LabelSource } from '../../../entities/LabelSource';
import { OutDocumenStatus } from '../../../entities/OutDocumentStatus';

import { validateArea } from '../../../utils/validation/order';
import { getNow, getNowNorwegian, str2datetime } from '../../../utils/datetime';
import { useAsyncColumnsStorage } from '../../../utils/hooks/useAsyncColumnsStorage';
import { useIsSearchInDocs } from "../../../utils/hooks/useIsSearchInDocs";
import { useExpandDefaultForAll } from "../../../utils/hooks/useExpandDefaultForAll";

import CustomBulkActionButtons from './CustomBulkActionButtons';

import { TotalPriceLabel } from './TotalPriceLabel';
import { OrdersFilter, useOrderFilterDefaultValues } from './ListFilters';
import { OrdersActions } from './Actions';
import { validateRankField } from '../../../utils/validation/order';
import { checkIsRankDisabled } from '../../../utils/checker/order';
import { Terminal } from '../../../entities/Terminal';
import { rankChoicesGetter } from '../../../utils/getter';
import { TooltipTextField } from '../../../components/fields/TooltipTextField';
import { getStatusAllowedIds } from '../forms/FormManager';
import { TIME_FORMAT } from '../../../components/_helpers/dateTime';

const defaultSort = {field: 'id', order: 'DESC'};

const BG_COLOR_WHITE = "#ffffff";
const BG_COLOR_GREEN = "#b7ff7d";
const BG_COLOR_DARKGREEN = "#008000";
const BG_COLOR_RED = "#ff8c8c";
const BG_COLOR_YELLOW = "#FFE600";
const BG_COLOR_DARKRED = '##C9211E';

const useStyles = makeStyles({
	list: {
		noResults: {
			backgroundColor: 'red',
			padding: 100,
		},
		actions: {
			backgroundColor: 'blue',
		},
	},
	expandIcon: {
		padding: "2px"
	}
});

const useCellStyles = makeStyles({
	cellGreen: {
		backgroundColor: BG_COLOR_GREEN, // green
	},
	cellRed: {
		backgroundColor: BG_COLOR_RED, // red
	},
	cellWhite: {
		backgroundColor: BG_COLOR_WHITE, // white
	},
	noPadding: {
		padding: '0',

		'&>span>button': {
			borderTopRightRadius: 0,
		},
	},
	noPaddingButton: {
		padding: '0',

		'&>span>button': {
			backgroundColor: BG_COLOR_WHITE,
			borderRadius: 3,
			border: "1px solid white",
			// outline: "1px solid white",
			minWidth: 'auto'
		},
	},
	noPaddingButtonStart: {

		'&>span>button': {
			borderTopRightRadius: 0,
			borderBottomRightRadius: 0,
			borderRight: "none"
		},
	},
	noPaddingButtonEnd: {
		'&>span>button': {
			borderTopLeftRadius: 0,
			borderBottomLeftRadius: 0,
			borderLeft: "none"
		},
	},
	cellEtaTruck:{		
		'.order-cc-late &>span>button': {
			// backgroundColor: BG_COLOR_RED,
		},
		'.order-car-detected-eta &>span>button':{
			// borderColor: BG_COLOR_DARKGREEN,
			backgroundColor: BG_COLOR_GREEN,
		}
	},
	cellEtdTruck:{
		'.order-cc-late &>span>button': {
			// backgroundColor: BG_COLOR_RED,
		},
		'.order-car-detected-etd &>span>button':{
			// borderColor: BG_COLOR_DARKGREEN,
			backgroundColor: BG_COLOR_GREEN,
		}
	},
	cellTime: {
		'&>span':{
			padding: '0 2px',
			border: "1px solid transparent",
			// outline: "1px solid transparent",
		},
		'.order-cc-late &>span': {
			// backgroundColor: BG_COLOR_RED,
			cursor: 'pointer',
		},
		'.order-cc-late &>span .tt-icon':{
			padding: '0 6px',
			backgroundColor: BG_COLOR_YELLOW,
			color: BG_COLOR_DARKRED,
			fontWeight: 'bold'
		}
	},
	cellEtaTime: {		
		'.order-car-detected-eta &>span':{
			// borderColor: BG_COLOR_DARKGREEN,
			outlineColor: 'white',
			backgroundColor: BG_COLOR_GREEN + '!important',
		},
		'.updated-eta-by-customer &>span': {
			// cursor: 'pointer',
			outlineColor: 'white',
			backgroundColor: BG_COLOR_RED,
		},
		// '.updated-eta-by-customer &>span .tt-icon':{
		// 	padding: '0 6px',
		// 	backgroundColor: BG_COLOR_YELLOW,
		// 	color: BG_COLOR_DARKRED,
		// 	fontWeight: 'bold'
		// }
	},
	cellEtdTime: {		
		'.order-car-detected-etd &>span': {
			// borderColor: BG_COLOR_DARKGREEN,
			outlineColor: 'white',
			backgroundColor: BG_COLOR_GREEN + '!important',
		},
		'.updated-etd-by-customer &>span': {
			// cursor: 'pointer',
			outlineColor: 'white',
			backgroundColor: BG_COLOR_RED,
		},
		// '.updated-etd-by-customer &>span .tt-icon':{
		// 	padding: '0 6px',
		// 	backgroundColor: BG_COLOR_YELLOW,
		// 	color: BG_COLOR_DARKRED,
		// 	fontWeight: 'bold'
		// }
	},
	// cellEtaTime: {
	// 	'.order-cc-late &>span': {
	// 		backgroundColor: BG_COLOR_RED,
	// 		color: BG_COLOR_GREEN,
	// 	},
	// },
	// cellEtdTime: {
	// 	'.order-cc-late &>span': {
	// 		backgroundColor: BG_COLOR_GREEN,
	// 		color: BG_COLOR_RED
	// 	},
	// },
});

const Label = ({title, label}) => {
	return (
		<Tooltip title={title} placement="bottom">
			<div>
				{label}
			</div>
		</Tooltip>
	);
};

const OrdersPagination = props => <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />;

const prepareEtaArrived = v => ({
	port_in: v.port_in,
	eta_time: v.eta_time,
	eta_slot_time: v.eta_slot_time,
	is_eta_arrived_manual: true,
	status: OrderStatus.ONE_IN_TERMINAL
});

const prepareEtdArrived = v => ({
	port_out: v.port_out,
	etd_time: v.etd_time,
	etd_slot_time: v.etd_slot_time,
	is_etd_arrived_manual: true,
	status: OrderStatus.ONE_IN_TERMINAL
});

const getFilterDataArray = (rec) => {
	switch (rec['link_order_type']){
		case LinkOrderType.trip_number:
			return  ['trip_number', rec['trip_number']];
		case LinkOrderType.etd_truck_id:
			return  ['etd_truck_id', rec['etd_truck_id']];
		case LinkOrderType.etd_truck:
			return  ['etd_truck', rec['etd_truck']];
		default: return [null, null];
	}
};

const TruckEditableConditionalField = ({...props }) => {
	const source = props.source;
	const record = props.record;
	
	if(record.service === OrderService.intoPlukkStorage && source === "eta" && record.eta_date == null){
		return null;
	}
	if(record.service === OrderService.intoPlukkStorage && source === "etd" && record.etd_date == null){
		return null;
	}
    return (
        <ButtonEditableCustomField
            {...props}
        />
    );
};



const DocumentsButton = (props) => {
	const [isOpenedPopover, setIsOpenedPopover] = React.useState(false)
	const popoverAnchor = React.useRef(null);

	const openPopover = () => {
		setIsOpenedPopover(true);
	};
  
	const closePopover = () => {
		setIsOpenedPopover(false);
	};

	const [link_order_key, link_order_value] = getFilterDataArray(props.record);

	const data = {
		outDocType: props.record['outgoing_doc_type'],
		outDocSrc: props.record['outgoing_doc_src'],
		outDocItems: props.record['outgoing_doc_items_with_max_status'],		
		suggested_area: props.record['suggested_area'],
		suggested_area_2: props.record['suggested_area_2'],
		outgoing_order_doc_status: props.record['outgoing_order_doc_status'],
		outDocStatuses: props.outDocStatuses,
		link_order_key: link_order_key,
		link_order_value: link_order_value
	};

	return (
	  <>
		<div ref={popoverAnchor} onMouseEnter={openPopover} onMouseLeave={closePopover}>
			<ShowButton
				to={`/${props.resource}/${props.record.id}/show/${props.pageIndex}`}
				icon={props.record.has_documents ? <DescriptionIcon/> : <SubjectIcon />}
				{...props}			
			/>
		</div>
		{props.record.outgoing_order_doc_status > OutDocumenStatus.none ?
		 <OutgoingDocItemsPopup anchorEl={popoverAnchor}
		    handlePopoverOpen={openPopover}
		 	handlePopoverClose={closePopover}
			isOpen={isOpenedPopover}
			data={data}			
		 /> 
		  : null}
	  </>
	);
};

const ActionButtonsField = ({
	isCompanyActive, isSearchInDocs,
	resource, basePath, record,
	outDocStatuses, comp_perm
}) => {
	const outgoing_doc_style = outDocStatuses && record && record.has_out_documents ? {
		backgroundColor: outDocStatuses[record.outgoing_order_doc_status] && outDocStatuses[record.outgoing_order_doc_status].color,
		border: '1px solid #000000'
	} : {};

	return <React.Fragment>
		{record.has_documents || record.has_out_documents ?
			<DocumentsButton label="" resource={resource} basePath={basePath} record={record} pageIndex={( (comp_perm & CompPerm.is_output_document) && record.has_out_documents ? 4: 3)} 
				style={{minWidth: 'auto', ...outgoing_doc_style}} outDocStatuses={outDocStatuses}/>
			:
			<EditButton label="" disabled={!isCompanyActive} style={{minWidth: 'auto', ...outgoing_doc_style}} resource={resource}
						basePath={basePath} record={record}/>
		}
		{isSearchInDocs && <Link to={`/${resource}/${record.id}/show`} target={"_blank"}>
			<Button style={{minWidth: 'auto', ...outgoing_doc_style}}><VisibilityIcon/></Button>
		</Link>}
	</React.Fragment>;
};

const GridFieldWithSelectionOnDoubleClick = ({ isEqual = false, component, ...props }) => {
	const listContext = useListContext();
	const refresh = useRefresh();
	const record = useRecordContext(props);

	const handleOnDoubleClick = useCallback(() => {
		const newSelectedIds = Object.values(listContext.data) 
									 .filter(row => row[props.source] === record[props.source])
									 .map(row => row.id);

		if (newSelectedIds.length !== 0 && !newSelectedIds.every(id => listContext.selectedIds.includes(id))) {
			listContext.onSelect(newSelectedIds);
			refresh();
		}
	}, [ listContext.data, listContext.selectedIds, props.source ]);

	const sharedProps = {
		style: { cursor: 'pointer' },
		title: "Double click to select same records",
		onDoubleClick: handleOnDoubleClick,
	};

	return React.createElement(component, { ...props, ...sharedProps });
};

const useDraggableMode = () => {
	const { permissions } = usePermissions();
	const listParamsData = useSelector(state => state.admin.resources['orders'] ? 
		state.admin.resources['orders'].list.params : {});

	return (
		permissions && 
		(permissions.role === UserRole.osfc_manager 
			|| permissions.role === UserRole.admin 
			|| permissions.role === UserRole.app_manager) &&
		listParamsData.sort === "rank" && 
		listParamsData.order === "DESC"
	);
};

export const RankField = ({isOSFCManager, rankPriorities, ...props}) => {

	let title = "";
	let rankStyle = {};
	if (rankPriorities && props.record && Number.isInteger(props.record.rank) && Number.isInteger(props.record.rank_auto)) {
		title = "Rank auto group - " + props.record.rank_auto
		rankStyle = {
			backgroundColor: rankPriorities[props.record.rank_auto] && rankPriorities[props.record.rank_auto].color,
			textAlign: "center",
			border: "1px solid #000000",
			borderRadius: "100%"
		};
	}
	// const choices = props.record.rank ?  [{id: false, name: "NO (" +  props.record.rank + ")"}, {id: true, name: "YES (M)"}] : 
	// 									 [{id: false, name: "NO ()"}, {id: true, name: "YES (M)"}];
	const rankChoices = rankChoicesGetter(props.record)

	return <TextEditableField title={title} style={rankStyle} choices={rankChoices} {...props}/> 
}


// const checkIfRankHidden = (filterValues) => filterValues &&
// 		filterValues['state'] === 'active' &&
// 		filterValues['terminal_id'] && Number.isInteger(filterValues['terminal_id'][0]) &&
// 		filterValues['eta_date|etd_date'] && filterValues['eta_date|etd_date'][0] === 'today'
// 	? {} : {rank:-1};


const FakeReferenceTextField = ({ source, replaceSource, ...props}) => <TextField {...props} source={replaceSource} />;

// Info: for better performance it would be better to calculate at backend side
const isOrderCCLate = rec => {
	if (
		rec["service"] === OrderService.reloadCarCar &&
		rec["eta_date"] && rec["eta_time"] && rec["etd_date"] && rec["etd_time"]
	) {
		const diffMin = differenceInMinutes(str2datetime(rec["eta_date"], rec["eta_time"]), str2datetime(rec["etd_date"], rec["etd_time"]));
		return diffMin > 15;
	}
	return false;
};

export const OrdersList = ({permissions, ...props}) => {
	const classes = useStyles();
	const cellClasses = useCellStyles();
	const aboveListRef = useRef(null);
	const translate = useTranslate();
	const t = (field) => translate(`resources.orders.fields.${field}`);
	const filterDefaultValues = useOrderFilterDefaultValues();
	const columnsAsyncStorage = useAsyncColumnsStorage("orders");
	const isSearchInDocs = useIsSearchInDocs(filterDefaultValues, props.resource);
	const commodityChoices = useCommodityChoices();
	const refresh = useRefresh();
	const [isBulkOper, setIsBulkOper] = React.useState(false);
	// const isDraggableMode = useDraggableMode();
	const isDraggableMode = false;
	const notify = useNotify();


	const [ mutate ] = useMutation({
		type: 'updatePartial',
		resource: 'orders',
	}, {
		withDeclarativeSideEffectsSupport: true,
		onSuccess: () => {
			refresh();
			notify(translate("ra.notification.updated", { smart_count: 1 }), {
				type: 'info',
				messageArgs: { smart_count: 1 },
			});
		},
		onFailure: (error) => {
			notify(error.message, "error");
		},
	});

	const { ids: _, data: statuses } = useGetList("order-statuses");

	const { ids: __, data: outDocStatuses } = useGetList(
		"outgoing-doc-statuses",
		{page: 0, perPage: -1},
		{field: 'id', order: 'ASC'},
	);

	const { ids: ___, data: rankPriorities } = useGetList(
		"rank-priorities",
		{page: 0, perPage: -1},
		{field: 'id', order: 'ASC'},
	);

	useEffect(() => {
		if (permissions?.time_refresh_order) {
			const intervalId = setInterval(refresh, permissions.time_refresh_order * 1000); 
			return () => clearInterval(intervalId);
		}
	}, [permissions]);
	
	useExpandDefaultForAll(props.resource);

	if (!permissions || filterDefaultValues === undefined || columnsAsyncStorage.columns === undefined) {
		return <Loading loadingSecondary=""/>;
	}

	const orderRowStyle = (record, index) => {
		return {
			backgroundColor: statuses[record.status] && statuses[record.status].color,
		};
	};

	

	const orderRowClass = rec => {
		const css = [];
		console.log(rec)
		if(isOrderCCLate(rec)){
			css.push("order-cc-late");
		}

		if (!OrderStatusNotactive.includes(rec.status)) {
			if (rec.service === OrderService.reloadCarTerminalCar) {
				if(rec.is_eta_arrived && [OrderStatus.BOOKED, OrderStatus.ONE_IN_TERMINAL, OrderStatus.LOADING_READY].includes(rec.status)){
					css.push("order-car-detected-eta");
				}
			} else {
				if(rec.is_eta_arrived){
					css.push("order-car-detected-eta");
				}
			}

			if(rec.is_etd_arrived){
				css.push("order-car-detected-etd");
			}
		}
		
		if(rec.is_eta_updated_by_customer){
			css.push("updated-eta-by-customer");
		}
		if(rec.is_etd_updated_by_customer){
			css.push("updated-etd-by-customer");
		}

		if (isCustomerAndTransporterCompany) {
			if ((userCompanyId && userCompanyId !== rec["company_id"])){			
				if (userCompanyId === rec["eta_3rd_party"]) {
					css.push("order-as-trans-eta");
				}
				if (userCompanyId === rec["etd_3rd_party"]) {
					css.push("order-as-trans-etd");			
				}
			}
		}		
		return css;
	};

	const handleOnDragEnd = ({ draggableId, dragRecord, dropRecord }) => {
		if (dragRecord.rank !== dropRecord.rank) {
			mutate({
				payload: {
					id: draggableId,
					data: { rank: dropRecord.rank },
				}
			})
		}
	};

	const userCompanyId = permissions && permissions.company_id;
	const isCompanyActive = !permissions['company_is_blocked'];
	const isOSFCManager = permissions && (
		permissions.role === UserRole.osfc_manager || permissions.role === UserRole.app_manager || permissions.role === UserRole.admin
	);
	const isOSFCUser = permissions && (
		permissions.role === UserRole.osfc_manager ||
		permissions.role === UserRole.osfc_employee || 
		permissions.role === UserRole.app_manager ||
		permissions.role === UserRole.admin
	);
	const isCustomerCompany = permissions && (
		permissions.role === UserRole.customer_manager
		|| permissions.role === UserRole.customer_employee
	);
	const isCustomerAndTransporterCompany = permissions && (
		permissions.role === UserRole.customer_and_transporter_manager ||
		permissions.role === UserRole.customer_and_transporter_employee ||
		permissions.role === UserRole.customer_and_transporter_driver
	);

	const isDriver = permissions && (
		permissions.role === UserRole.customer_and_transporter_driver ||
		permissions.role === UserRole.transporter_driver
	);

	const isNot3rdPartyRole = UserRoleByCompanyType.customer_and_transporter_roles.indexOf(permissions.role) === -1
	const canMultipleChanges = isOSFCUser;

	const exposedColumns = ['rank'];

	const msgMsgNotChangeTruckArrived = translate("resources.orders.messages.warn.not-change-truck-arrived");
	const msgSystemWillSetNow = translate("resources.orders.messages.warn.system-will-set-now");

	return (
		<div ref={aboveListRef}>
			<List
				{...props}
				perPage={50}
				empty={false}
				pagination={<OrdersPagination/>}
				classes={classes.list}
				exporter={false}
				bulkActionButtons={canMultipleChanges && isBulkOper && <CustomBulkActionButtons />}
				filters={<OrdersFilter hasCompanyFilter={isOSFCUser}
									hasOrderTypeFilter={isCustomerAndTransporterCompany}
									isOnlyDates={isDriver}
									isSearchInDocs={isSearchInDocs}/>}
				filterDefaultValues={filterDefaultValues}
				sort={defaultSort}
				hasCreate={props.hasCreate && isCompanyActive}
				actions={<OrdersActions/>}
			>
				<WrapperWithDragAndDrop 
					resource={props.resource} 
					onDragEnd={handleOnDragEnd} 
					isDraggable={isDraggableMode}
				>
				<ScrollingWrapper> 
					<Datagrid rowStyle={orderRowStyle}
						classes={{expandIcon: classes.expandIcon}}
						storage={columnsAsyncStorage}
						isRowExpandable={() => isSearchInDocs}
						expand={isSearchInDocs ? <SearchResultDocumentsRowExpand /> : false}
						expandIconCell={{padding: "2px"}}
						columnsLabel={translate('osfc.filters.labels.columns')}
						canMultipleChanges = {canMultipleChanges}
						isBulkOper = {isBulkOper}
						setIsBulkOper = {setIsBulkOper}
						configurationLabel={translate('osfc.filters.labels.configuration')}
						exposedColumns={exposedColumns}
						// getHiddenColumns={checkIfRankHidden}
					>
						<CssRowFieldCellWrapper cssFn={orderRowClass} />
						<ActionButtonsField isCompanyActive={isCompanyActive} isSearchInDocs={isSearchInDocs} outDocStatuses={outDocStatuses} comp_perm={permissions.comp_perm} />

						<ChatMessageCountField source="message_count" label="" sortable={false}/>

						{(isOSFCUser || isCustomerAndTransporterCompany) && <CustomerField source="company_id" formatLabel={(record) => record["customer_name"]} />}
						<PriorityField source="priority" label={<Label title={t('priority')} label="P"/>}
									sortable={false}/>
						{/* {isOSFCManager ? <RankField editable={isOSFCManager}
										 		source={"rank"}
										 		editLabel={translate("resources.orders.fields.rank.edit.label")}
												EditComponent={YesNoInput}
												checkIsDisabled={checkIsRankDisabled}
												rankPriorities={rankPriorities}
												// validate={validateRankField}
												format={val=> val === 'M' ? true : val===null || val===undefined ? false : typeof val === 'string' ? false : true }
												parse={val=> val === true ? 0 : null}
												{...props}
										/>
										: null} */}
						{isOSFCManager ? <RankField editable={isOSFCManager}
										 		source="rank"
										 		editLabel={translate("resources.orders.fields.rank.edit.label")}
												EditComponent={YesNoInput}
												checkIsDisabled={checkIsRankDisabled}
												rankPriorities={rankPriorities}
												formatField={val=> val ? val : val === 0 ? 'M' : null}
										/>
										: null}
						<EditButtonField source="reference" disabled={!isCompanyActive}/>
						<ReferenceEditableField
							optimized={true}
							editable={isOSFCManager ? true : isCustomerCompany ? rec => OrderStatusCustomerEdit.indexOf(rec["status"]) !== -1 : isCustomerAndTransporterCompany && !isDriver ? rec => OrderStatusCustomerEdit.indexOf(rec["status"]) !== -1 && userCompanyId === rec["company_id"] : false}
							permissions={permissions}
							source="status"
							reference="order-statuses"
							additionalParams={['set_order_completed']}
							editField={isOSFCUser ?
								(rec => {
									const statusAllowedIds = getStatusAllowedIds(rec, permissions.role);
									return statusAllowedIds ? <SelectDisabledInput allowedIds={statusAllowedIds} optionText="name" /> : <SelectInput optionText="name"/>;
								}) :
								<SelectDisabledInput allowedIds={OrderStatusCustomerEdit} optionText="name"/>
						}>
							<FakeReferenceTextField source="status_id" replaceSource="status_name" />
						</ReferenceEditableField>
						<ObjectChipField source="service" object={OrderServices} titleKey="short_name" tooltipKey="full_name"/>
						<FakeReferenceTextField source="terminal_id" replaceSource="terminal_name" label={t("terminal")}/>
						<TextField source="et_date_union" />

						<DateFormatField source="eta_date" headerClassName={cellClasses.cellGreen} />

						<TooltipTextField source="eta_time" headerClassName={cellClasses.cellGreen} cellClassName={cellClasses.cellTime + " " + cellClasses.cellEtaTime} ttFn={rec => isOrderCCLate(rec) ? translate("resources.orders.messages.is-order-cc-late") : false} />						
						{/* <TextField source="eta_time" headerClassName={cellClasses.cellGreen} cellClassName={cellClasses.cellEtaTime} /> */}

						<TextField source="eta_slot_time" headerClassName={cellClasses.cellGreen} />

						{isOSFCUser ?
							<ReferenceEditableField source="port_in" reference="ports"
								optimized={true}
								headerClassName={cellClasses.cellGreen}
								permissions={permissions}
								editField={<SelectInput optionText="name" emptyValue="" />}
								filter={rec => ({ terminal_id: [rec.terminal_id] })}
								allowEmpty={true}
								format={value => value === 0 ? '' : value} 
								parse={value => value === '' ? 0 : value}
							>
								<FakeReferenceTextField source="port_in" replaceSource="port_in_name" />
							</ReferenceEditableField> :
							<FakeReferenceTextField source="port_in" replaceSource="port_in_name" headerClassName={cellClasses.cellGreen} />
						}
						<CustomerField source="eta_3rd_party" formatLabel={(record) => record["eta_3rd_party_name"]} headerClassName={cellClasses.cellGreen}/>
						{ isNot3rdPartyRole ? <YesNoField source="eta_show_doc_for_trans" headerClassName={cellClasses.cellGreen} label={t("eta_show_doc")}/> : null}

						{isCustomerAndTransporterCompany && <TransDriverField source="eta_driver" refSource="eta_driver_id" prefix="eta" formatLabel={(record) => record["eta_driver_name"]} headerClassName={cellClasses.cellGreen} />}

						{ isOSFCManager && isBulkOper ? 
							<GridFieldWithSelectionOnDoubleClick component={FakeReferenceTextField} source="eta_truck_id" replaceSource="eta_truck_name" label={t("eta_truck")} headerClassName={cellClasses.cellGreen} />
						:	
							<FakeReferenceTextField source="eta_truck_id" replaceSource="eta_truck_name" label={t("eta_truck")} headerClassName={cellClasses.cellGreen} />
						}

						<FakeReferenceTextField source="eta_trailer_id" replaceSource="eta_trailer_name" label={t("eta_trailer")} headerClassName={cellClasses.cellGreen} />
						<LongTextField source="place_from" headerClassName={cellClasses.cellGreen}/>
						{/* <TextField source="assignee_1" headerClassName={cellClasses.cellGreen} /> */}
						{isOSFCUser &&  <ReferenceEditableField source="assignee_1" reference="osfc-users"
								optimized={true}
								headerClassName={cellClasses.cellGreen}
								permissions={permissions}
								editField={<SelectInput optionText={rec => rec.first_name + ' ' + rec.last_name} emptyValue="" />}
								filter={{ no_range: true, is_terminal: true, ...props.filter }}
								allowEmpty={true}
								format={value => value === 0 ? '' : value} 
								parse={value => value === '' ? 0 : value}
							>
								<FakeReferenceTextField source="assignee_1" replaceSource="assignee_1_name" />
							</ReferenceEditableField>
						}

						{isOSFCUser && <TruckEditableConditionalField source="eta" title={<ArrivedIcon 
							style={{color: '#27ae60'}} fontSize="small"/>}
																	headerClassName={cellClasses.cellGreen}
																	cellClassName={cellClasses.noPaddingButton + " " + cellClasses.noPaddingButtonStart + " " + cellClasses.cellEtaTruck}
																	btnLabel={translate("osfc.buttons.truck_arrived")}
																	prepareValues={prepareEtaArrived}
																	updateType="updatePartial"
																	moreFields={["terminal_id", "is_eta_arrived"]}>
																		
							<FunctionField source="eta_time" render={record => {
								const props = record["is_eta_arrived"] ? {
									source: "eta_time",
									title: msgMsgNotChangeTruckArrived
								} : {
									source: "eta_time_now",
									defaultValue: dateFnsFormat(getNowNorwegian(), TIME_FORMAT),
									title: msgSystemWillSetNow
								};								
								return <GridInput xs={12} component={TimeInput} margin="dense" label="resources.orders.fields.eta_time" disabled {...props} />;
							}}/>
							{/* <GridInput xs={12} component={DateInput} margin="dense" disabled title="test" helperText="test 2"
									label="resources.orders.fields.eta_date" source="eta_date"/>
							<GridInput xs={12} component={TimeInput} margin="dense" disabled title="test" helperText="test 2"
									label="resources.orders.fields.eta_time" source="eta_time"/> */}
							<GridInput xs={12} component={TimeInput} margin="dense"
									label="resources.orders.fields.eta_slot_time" source="eta_slot_time"/>
							<FunctionField source="port_in" render={record => 
								<GridInput xs={12} component={PortInput} 
										   label="resources.orders.fields.port_in" source="port_in" 
										   filter={{ terminal_id: [record.terminal_id] }} 
								/>}
							/>
						</TruckEditableConditionalField>}
						{isOSFCUser && <TruckEditableConditionalField source="etd" title={<ArrivedIcon style={{color: '#e74c3c'}}
																						fontSize="small"/>}
																	headerClassName={cellClasses.cellRed}
																	btnLabel={translate("osfc.buttons.truck_arrived")}
																	cellClassName={cellClasses.noPaddingButton + " " + cellClasses.noPaddingButtonEnd+ " " + cellClasses.cellEtdTruck}
																	prepareValues={prepareEtdArrived}
																	updateType="updatePartial"
																	moreFields={["terminal_id", "is_etd_arrived"]}>
							<FunctionField source="etd_time" render={record => {
								const props = record["is_etd_arrived"] ? {
									source: "etd_time",
									title: msgMsgNotChangeTruckArrived
								} : {
									source: "etd_time_now",
									defaultValue: dateFnsFormat(getNowNorwegian(), TIME_FORMAT),
									title: msgSystemWillSetNow
								};								
								return <GridInput xs={12} component={TimeInput} margin="dense" label="resources.orders.fields.etd_time" disabled {...props} />;
							}}/>
							{/* <GridInput xs={12} component={TimeInput} margin="dense"
									label="resources.orders.fields.etd_time" source="etd_time"/> */}
							<GridInput xs={12} component={TimeInput} margin="dense"
									label="resources.orders.fields.etd_slot_time" source="etd_slot_time"/>
							<FunctionField source="port_out" render={record => 
								<GridInput xs={12} component={PortInput} 
										   label="resources.orders.fields.port_out" source="port_out" 
										   filter={{ terminal_id: [record.terminal_id] }} 
								/>}
							/>
						</TruckEditableConditionalField>}

						<DateFormatField source="etd_date" headerClassName={cellClasses.cellRed} />

						<TooltipTextField source="etd_time" headerClassName={cellClasses.cellRed} cellClassName={cellClasses.cellTime + " " + cellClasses.cellEtdTime} ttFn={rec => isOrderCCLate(rec) ? translate("resources.orders.messages.is-order-cc-late") : false} />
						{/* <TextField source="etd_time" headerClassName={cellClasses.cellRed} cellClassName={cellClasses.cellEtdTime} /> */}

						<TextField source="etd_slot_time" headerClassName={cellClasses.cellRed} />

						{isOSFCUser ?
							<ReferenceEditableField source="port_out" reference="ports"
								optimized={true}
								headerClassName={cellClasses.cellRed}
								permissions={permissions}
								editField={<SelectInput optionText="name" emptyValue=""/>}
								filter={rec => ({ terminal_id: [rec.terminal_id] })}
								allowEmpty={true}
								format={value => value === 0 ? '' : value} 
								parse={value => value === '' ? 0 : value}
							>
								<FakeReferenceTextField source="port_out" replaceSource="port_out_name" />
							</ReferenceEditableField> :
							<FakeReferenceTextField source="port_out" replaceSource="port_out_name" headerClassName={cellClasses.cellRed} />
						}
						<CustomerField source="etd_3rd_party" formatLabel={(record) => record["etd_3rd_party_name"]} headerClassName={cellClasses.cellRed}/>						
						{ isNot3rdPartyRole ? <YesNoField source="etd_show_doc_for_trans" headerClassName={cellClasses.cellRed} label="resources.orders.fields.etd_show_doc"/> : null}

						{isCustomerAndTransporterCompany && <TransDriverField source="etd_driver" refSource="etd_driver_id" prefix="etd" formatLabel={(record) => record["etd_driver_name"]} headerClassName={cellClasses.cellRed} />}

						{ isOSFCManager && isBulkOper ?
							<GridFieldWithSelectionOnDoubleClick component={FakeReferenceTextField} source="etd_truck_id" replaceSource="etd_truck_name" label={t("etd_truck")} headerClassName={cellClasses.cellRed} />
						:	
							<FakeReferenceTextField source="etd_truck_id" replaceSource="etd_truck_name" label={t("etd_truck")} headerClassName={cellClasses.cellRed} />
						}

						<FakeReferenceTextField sortable={false} source="etd_trailer_id" replaceSource="etd_trailer_name" label={t("etd_trailer")} headerClassName={cellClasses.cellRed} />
						<LongTextField source="place_to" headerClassName={cellClasses.cellRed} />

						{isOSFCUser && <ReferenceEditableField source="assignee_2" reference="osfc-users"
								optimized={true}
								headerClassName={cellClasses.cellRed}
								permissions={permissions}
								editField={<SelectInput optionText={rec => rec.first_name + ' ' + rec.last_name} emptyValue="" />}
								filter={{ no_range: true, is_terminal: true, ...props.filter }}
								allowEmpty={true}
								format={value => value === 0 ? '' : value} 
								parse={value => value === '' ? 0 : value}
							>
								<FakeReferenceTextField source="assignee_2" replaceSource="assignee_2_name" />
							</ReferenceEditableField>
						}					
						{ isOSFCManager && isBulkOper ?
							<GridFieldWithSelectionOnDoubleClick component={TextField} source="trip_number" />
						:
							<TextField source="trip_number"/>
						}
						<BooleanField source="plumb_seal" label={<Label title={t('plumb_seal')} label="S"/>}
									sortable={false}/>
						<NoneOrValueField source="printed_doc" label={<Label title={t('printed_doc')} label="D"/>}
										sortable={false}/>

						<ShowButtonField source="total_price"
										label={isOSFCManager ? <TotalPriceLabel source="total_price" resource={props.resource}/> : <Label title={t('total_price')} label={t('total_price')}/> }
										formatter={toPrice} sortable={false}/>
						<SelectField source="commodity" choices={commodityChoices} sortable={false} />
						<EqualField source="cooler_type_id" to={CoolerType.freezer}
									label={<Label title={t('cooler_type_freezer')} label="F"/>} sortable={false}/>
						<LongTextField source="notes" sortable={false}/>
						<FunctionField source="area_id" render={record => 
								<>
									{isOSFCUser ?
									<ReferenceEditableField source="area_id" reference="areas"
									    record={record}
										optimized={true}
										permissions={permissions}
										editField={<SelectInput optionText="name" emptyValue="" disableValue="disabled_by_any" />}
										filter={{ terminal_id: [record.terminal_id] }}
										allowEmpty={true}
										format={value => value === 0 ? '' : value} 
										parse={value => value === '' ? 0 : value}
										validate={(value, _) => validateArea(value, record)}
										{...props}
									>
										<FakeReferenceTextField source="area_id" replaceSource="area_name" />
									</ReferenceEditableField>
									 :
										<FakeReferenceTextField source="area_id" replaceSource="area_name" label={t("area_id")} />
									}
								</>
							}
						/>
						{isOSFCUser ? <YesNoField source="is_area_blocked" /> : null}
						<TextField source="pallets"/>
						<TextField source="boxes"/>
						<TextField source="label"/>
						<SelectEnumField source="label_source" enumName="label_source" enumObj={LabelSource} />
						<TextField source="kilos"/>
						<YesNoField source="is_doc_for_nfsa"/>
						<LongTextField source="internal_notes" sortable={false} />
					</Datagrid>
				</ScrollingWrapper>
				</WrapperWithDragAndDrop>
			</List>
		</div>
	);
};
