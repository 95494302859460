import * as React from 'react';
import {useHistory} from "react-router-dom";
import {Field, Form} from 'react-final-form';
import {Input, useStyles} from "../components/_extensions/react-admin/ra-ui-materialui/src/auth/LoginFormEx"

import {Button, CardActions} from '@material-ui/core';
import {useTranslate, useNotify, useSafeSetState} from 'ra-core';
import { resetPassword } from '../api/authProvider';
import {confirmPasswordValidator} from "../utils/validation/general";


export const ResetPasswordForm = ({request_id, request_code, created_at, user_id, email, ...props}) => {
	const [loading, setLoading] = useSafeSetState(false);
	const translate = useTranslate();
	const notify = useNotify();
	const classes = useStyles(props);
	const history = useHistory();

	const validate = (values) => {
		const errors = {username: undefined};

		if (!values.username) {
			errors.username = translate('ra.validation.required');
		}
		if (!values.password) {
			errors.password = translate('ra.validation.required');
		}
		return errors;
	};

	const submit = values => {
		setLoading(true);
		resetPassword({
			email: values.username,
			user_id: user_id,
			request_id: request_id,
			request_code: request_code,
			created_at: created_at,
			new_password: values.password
		})
			.then((data) => {
				notify("Check your email for get password", {type: "success", autoHideDuration: 10000})
				history.push({
					pathname: "/login",
					state: {email: data.json.email}
				});
			})
			.catch(error => {
				setLoading(false);
				notify(
					typeof error === 'string'
						? error
						: typeof error === 'undefined' || !error.message
							? 'ra.auth.sign_in_error'
							: error.message,
					'warning',
					{
						_:
							typeof error === 'string'
								? error
								: error && error.message
									? error.message
									: undefined,
					}
				);
			});
	};

	return (
		<Form
			onSubmit={submit}
			validate={validate}
			render={({handleSubmit}) => (
				<form onSubmit={handleSubmit} noValidate>
					<div className={classes.form}>
						<div className={classes.input}>
							<Field
								autoFocus
								id="username"
								name="username"
								component={Input}
								label={translate('ra.auth.username')}
								disabled={loading}
								defaultValue={email}
							/>
						</div>
						<div className={classes.input}>
							<Field
								id="password"
								name="password"
								component={Input}
								label={translate('resources.user-passwords.fields.password')}
								type="password"
								disabled={loading}
								autoComplete="current-password"
								// helperText={<HelperPasswordTextComponent/>}
							/>
						</div>
						<div className={classes.input}>
							<Field
								id="confirm_password"
								name="confirm_password"
								component={Input}
								label={translate('resources.user-passwords.fields.confirm_password')}
								type="password"
								validate={(value, values) => translate(confirmPasswordValidator(value, values))}
								disabled={loading}
								autoComplete="current-password"
							/>
						</div>
					</div>
					<CardActions>
						<Button
							variant="contained"
							type="submit"
							color="primary"
							className={classes.button}
						>
							Reset password
						</Button>
					</CardActions>
				</form>
			)}
		/>
	);
};
