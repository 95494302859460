import React from 'react';
import './index.css';

import { Admin, adminReducer } from 'react-admin';
import { Redirect } from 'react-router-dom';

import { MyLayout } from './Layout';
import Menu from './Menu';

import {
	_getPermissions,
	getAuthProvider,
	dataProvider,
} from '../api/';
// import { i18nProvider } from '../translation/';
import { i18nProviderStatic } from '../translation/staticProvider';
import { osfcDefault as theme } from '../themes/osfcDefault';
import { customRoutesByPermissions, resourcesByPermissions } from './roles';
import { MyLoginPage } from './Login';

import { SocketProvider } from '../contexts/SocketProvider';

const initialState = adminReducer(undefined, { type: '@@INIT' });

const customAdminReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_SIDEBAR_OPEN':
			return {
				...state,
				ui: { ...state.ui, sidebarOpen: action.payload },
			};
		default:
			return adminReducer(state, action);
	}
};

const App = (props) => {
	const permissions = _getPermissions();

	const [customRoutes, setCustomRoutes] = React.useState(
		customRoutesByPermissions(permissions)
	);

	const updateCustomRoutes = React.useCallback((new_permissions) => {
		setCustomRoutes(customRoutesByPermissions(new_permissions));
	}, []);

	const publicBaseRoutes = new Set(["/forgot-password", "/reset-password"]);
	return (
		<Admin
			theme={theme}
			layout={(layoutProps) => (
				<SocketProvider>
					<MyLayout {...layoutProps} />
				</SocketProvider>
			)}
			customReducers={{ admin: customAdminReducer }}
			menu={Menu}
			customRoutes={customRoutes}
			authProvider={getAuthProvider(publicBaseRoutes, updateCustomRoutes)}
			dataProvider={dataProvider}
			// i18nProvider={i18nProvider}
			i18nProvider={i18nProviderStatic}
			loginPage={MyLoginPage}
			catchAll={() => <Redirect to="/" />}
		>
			{resourcesByPermissions}
		</Admin>
	);
};

export default App;
